<template>
  <div>
    <portal to="page-top-title">New Category</portal>
    <portal to="page-top-right"/>

    <box-form action="create" data-prop="formData" url="/templates/categories" redirect="templates-categories-list" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input>
            <form-group-select name="group_id" title="Group" :model="formData.group_id"
                               :options="groups" :disabled="!groups.length" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import Form from '@/modules/utils/Form'

export default {
  name: 'TemplatesCategoriesNew',
  metaInfo () {
    return { title: 'Templates | Categories | New' }
  },
  components: {
    BoxForm,
    FormGroupCheck,
    FormGroupInput,
    FormGroupSelect
  },
  data () {
    return {
      groups: [],
      formData: {
        name: Form.types.input(),
        group_id: Form.types.select(),
        active: Form.types.boolean(true)
      }
    }
  },
  created () {
    this.loadGroups()
  },
  methods: {
    loadGroups () {
      this.$http
        .get('/category-groups', { params: { no_pages: 1 } })
        .then((res) => {
          const { data: { data } } = res

          this.groups = data.map((group) => { return { id: group.id, name: group.name } })
        })
    }
  }
}
</script>
